<template>
	<div class="page">
		<div class="title">
			<div class="titleL">消费订单详情</div>
			<div class="titleR">
				<el-button type="infor" @click="$router.back()">返回</el-button>
			</div>
		</div>
		<!-- <div class="tit">
			<div class="titL">
				<span class="titText">会员资料</span>
				<img class="editImg" src="../../../assets/img/shouyin/edit.png" @click="dialogEditUser_state=true"/>
			</div>
			<div class="titR">
				<el-button type="infor" @click="$router.push({path:'/huiyuan/huiyuanOrderSee?id='+orderDetail.id})">订单记录</el-button>
			</div>
		</div> -->
		<!-- 会员资料 -->
		<div class="msgBox">
			<el-row>
				<el-col class="msgItem" :span="6">
					订单号：{{orderDetail.orderNo}} 
				</el-col>
				<el-col class="msgItem" :span="6">
					顾客：{{orderDetail.userInfo.userName}} 
				</el-col>
				<el-col class="msgItem" :span="6">
					手机号：{{orderDetail.userInfo.userMobile}}
				</el-col>
				<el-col class="msgItem" :span="6">
					订单状态：{{$public.getMatchingItem(stateList,'value',orderDetail.orderState).label}}
				</el-col>
			</el-row>
			<el-row>
				<el-col class="msgItem" :span="6">
					退货日期：
				</el-col>
				<el-col class="msgItem" :span="6">
					创建人：{{orderDetail.operationInfo.create.handleUserName}} 
				</el-col>
				<el-col class="msgItem" :span="6">
					创建日期：{{$public.FTime(orderDetail.operationInfo.create.handleTimeStamp,"YYYY-MM-DD")}}
				</el-col>
				<el-col class="msgItem" :span="6">
					订单金额：{{orderDetail.totalPrice}}
				</el-col>
			</el-row>
			<el-row>
				<el-col class="msgItem" :span="6">
					优惠券：
					<span v-if="orderDetail.couponInfo">
						{{orderDetail.couponInfo.couponName}} ({{orderDetail.couponInfo.couponNo}})
					</span>
				</el-col>
				<el-col class="msgItem" :span="6">
					订单备注：{{orderDetail.remark}} 
				</el-col>
			</el-row>
			<el-row>
				<el-col class="msgItem" :span="6">
					结算方式：
					<span v-if="!$public.isNull(orderDetail.payInfo)">
						<span v-for="(item,index) in orderDetail.payInfo.payList" :key="index">
							<span >
								{{$public.getMatchingItem(paymentList,'value',item.payType).label}}
								{{item.money}}
							</span>
						</span>
					</span>
					<el-button v-if="$buttonAuthority('order-orderList-see-editPay')" type="text"  @click="dialogSettlement_state=true">修改</el-button>
				</el-col>
				<el-col class="msgItem" :span="6">
					结算备注：
					<span v-if="!$public.isNull(orderDetail.payInfo)">
						{{orderDetail.payInfo.payRemark}}
					</span>
				</el-col>
			</el-row>
		</div>
		<div class="tit">
			<div class="titL">
				<span class="titText">商品信息</span>
			</div>
			<div class="titR">
				<el-button v-if="orderDetail.orderState===3&&$buttonAuthority('order-orderList-see-returnGoods')" type="primary" @click="dialogReturn_state=true">退货</el-button>
			</div>
		</div>
		<!-- 商品信息 -->
		<div class="goodsList">
			<el-table :data="orderDetail.orderItemList?orderDetail.orderItemList:[]" style="width: 100%" height="100%">
				<el-table-column label="" type="expand">
					<template slot-scope="scope">
						<el-tag v-for="(item,index) in scope.row.orderItemDetailList" :key="index" style="margin-right: 5px;">{{item.productName}}-{{item.modelName}}-{{item.num}}个</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="消费项目">
					<template slot-scope="scope">
						{{scope.row.productName}}
					</template>
				</el-table-column>
				<el-table-column label="宠物">
					<template slot-scope="scope">
						{{scope.row.petInfo.petName}}
					</template>
				</el-table-column>
				<el-table-column label="规格">
					<template slot-scope="scope">
						{{scope.row.modelName}}
					</template>
				</el-table-column>
				<el-table-column label="零售价">
					<template slot-scope="scope">
						{{scope.row.originalPrice}}
					</template>
				</el-table-column>
				<el-table-column label="活动">
					<template slot-scope="scope">
						{{scope.row.orderItemActivity.activityName}}
					</template>
				</el-table-column>
				<el-table-column label="优惠价">
					<template slot-scope="scope">
						{{scope.row.price}}
					</template>
				</el-table-column>
				<el-table-column label="数量">
					<template slot-scope="scope">
						{{scope.row.num}}
					</template>
				</el-table-column>
				<el-table-column label="总金额">
					<template slot-scope="scope">
						{{$public.cheng(scope.row.num,scope.row.price)}}
					</template>
				</el-table-column>
				<el-table-column label="销售人员">
					<template slot-scope="scope">
						<span v-for="(item,index) in scope.row.salesList" :key="index"> {{item.name}} </span>
						<img v-if="'order-orderList-see-editStaff'" class="editImg" @click="open_dialogChangeStaffs(scope.row)" src="../../../assets/img/shouyin/edit.png"/>
					</template>
				</el-table-column>
				<!-- <el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="$router.push({path:'/huiyuan/petSee?userId='+orderDetail.id+'&petId='+scope.row.id})">查看</el-button>
						<el-button type="text" size="small" @click="curPetItem=scope.row;dialogEditPet_state=true">编辑</el-button>
						<el-button type="text" size="small" @click="curPetItem=scope.row;deletePet()">删除</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</div>
		<div v-if="dialogReturn_state">
			<dialogReturn :orderDetail="orderDetail" @submit="submit_dialogReturn"></dialogReturn>
		</div>
		<div v-if="dialogChangeStaffs_state">
			<dialogChangeStaffs :salesList="curRow.salesList" @submit="submit_dialogChangeStaffs"></dialogChangeStaffs>
		</div>
		<div v-if="dialogSettlement_state">
			<dialogSettlement :type="'change'" :userId="orderDetail.userInfo.userId" :params="orderDetail" @submit="submit_dialogSettlement"></dialogSettlement>
		</div>
	</div>
</template>
<script>
	import dialogChangeStaffs from "../dialogChangeStaffs.vue"
	import dialogSettlement from "../../../components/dialogSettlement.vue"
	import dialogReturn from "../dialogReturn.vue"
	export default {
		components: {dialogChangeStaffs,dialogSettlement,dialogReturn},
		data() {
			return {
				orderDetail:{
					userInfo:{},
					payInfo:{},
					operationInfo:{create:{}},
					orderItemList:[],
					couponInfo:{},
				},
				// 修改销售
				dialogChangeStaffs_state:false,
				curRow:{},
				// 修改支付方式
				dialogSettlement_state:false,
				// 退货
				dialogReturn_state:false,
			}
		},
		computed:{
			stateList(){
				let list = this.$public.getOrderStateList()
				list.unshift({label:"全部",value:-1})
				return list
			},
			paymentList(){
				let list = this.$public.getPaymentList()
				list.unshift({label:"全部",value:-1})
				return list
			},
		},
		mounted() {
			this.getDetail();
		},
		methods: {
			// 获取用户详情
			getDetail(){
				let params = {
					orderNo:this.$route.query.no
				}
				let loading = this.$loading()
				this.$http.get("/order/OrderConsume/GetInfo",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.orderDetail = res.data
					}
				})
			},
			// 修改销售人员
			open_dialogChangeStaffs(row){
				this.curRow = row;
				this.dialogChangeStaffs_state = true
			},
			submit_dialogChangeStaffs(obj){
				this.dialogChangeStaffs_state = false;
				if(obj.type==="success"){
					let params = {
						"orderNo": this.$route.query.no,
						"orderItemSaleMam": [
							{
								"orderItemId": this.curRow.itemId,
								"salesList": obj.data
							}
						]
					}
					let loading = this.$loading()
					this.$http.post("/order/OrderConsume/UpdateSaleManMore",params).then(res=>{
						loading.close()
						if(res.code===0){
							this.$message({
								message: '修改成功',
								type: 'success'
							})
							this.getDetail();
						}
					})
				}
			},
			// 修改支付方式
			submit_dialogSettlement(obj){
				this.dialogSettlement_state = false;
				if(obj.type==='success'){
					this.getDetail();
				}
			},
			// 退货
			submit_dialogReturn(obj){
				this.dialogReturn_state = false;
				if(obj.type==='success'){
					this.getDetail();
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
	.page {
		width:100%;height: 100%;border-radius: 20px;overflow: hidden;color: $fontColor;background-color: #fff;
		.title{
			display: flex;justify-content: space-between;border-bottom: 1px solid #f2f2f2;height: 68px;box-sizing: border-box;padding:14px 20px;
			.titleL{
				font-size: 16px;color:$fontColor;line-height: 40px;
			}
			.titleR{
				
			}
		}
		.tit{
			padding:32px 20px 8px;display: flex;justify-content: space-between;
			.titL{
				font-size: 16px;color:$fontColor;background: url(../../../assets/img/cangku/detailName.png) 2px 8px no-repeat;background-size: 24px 24px;
				.titText{
					padding-left:12px;display:inline-block;line-height: 40px;
				}
				.editImg{
					width:20px;height:20px;margin-left:8px;vertical-align: middle;cursor: pointer;
				}
			}
		}
		.msgBox{
			margin:0 20px;background-color: #FFFCF8;padding:20px 30px;box-sizing: border-box;border-radius: 20px;margin-top:30px;
			.msgItem{
				font-size: 14px;color:$fontColor;line-height: 40px;
				.sexImg{
					width:20px;height: 20px;vertical-align: middle;
				}
			}
		}
		.goodsList{
			height: calc(100% - 379px);
		}
		.editImg{
			width:20px;height:20px;margin-left:8px;vertical-align: middle;cursor: pointer;
		}
	}
</style>
