<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">退货</div>
				<img class="HSDialogCloseImg" src="../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="width:1000px">
				<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="100px">
					<el-row>
						<el-col :span="24">
							<el-form-item label="退货备注">
								<el-input v-model="remark" placeholder="请输入退货备注"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item label="是否退优惠券">
								<el-radio-group v-model="radio">
									<el-radio :label="true">是</el-radio>
									<el-radio :label="false">否</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<el-row>
					<el-col :span="24">
						<div style="padding:0 20px 10px;font-size: 14px;">请选择要退回的商品</div>
					</el-col>
				</el-row>
				<el-table :data="list" style="width: 100%;padding:0 20px;" height="400px">
					<el-table-column label="消费项目">
						<template slot-scope="scope">
							<el-checkbox v-model="scope.row.checked">
								{{scope.row.productName}}
							</el-checkbox>
						</template>
					</el-table-column>
					<el-table-column label="规格">
						<template slot-scope="scope">
							{{scope.row.modelName}}
						</template>
					</el-table-column>
					<el-table-column label="优惠价">
						<template slot-scope="scope">
							{{scope.row.price}}
						</template>
					</el-table-column>
					<el-table-column label="数量">
						<template slot-scope="scope">
							{{scope.row.num}}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
		<div v-if="dialogSettlement_state">
			<dialogSettlement :type="'return'" :userId="orderDetail.userInfo.userId" :params="settlementParams" @submit="submit_dialogSettlement"></dialogSettlement>
		</div>
	</div>
</template>
<script>
	import dialogSettlement from "../../components/dialogSettlement.vue"
	export default {
		components:{dialogSettlement},
		data() {
			return {
				// 预约信息
				remark:'',
				radio:true,
				
				list:[],
				// 结算
				dialogSettlement_state:false,
				settlementParams:{},
			}
		},
		computed:{
			
		},
		props:{
			orderDetail:{
				default:()=>{
					return {}
				}
			}
		},
		created(){
			if(this.$public.isNull(this.orderDetail.orderItemList)){
				this.orderDetail.orderItemList = []
			}
			let list = []
			for(let i=0;i<this.orderDetail.orderItemList.length;i++){
				let item = this.orderDetail.orderItemList[i]
				this.$set(item,'checked',false)
				list.push(item)
			}
			this.list = list
		},
		mounted(){
			
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				let arr = []
				let total = 0
				for(let i=0;i<this.list.length;i++){
					if(this.list[i].checked===true){
						arr.push({
							"itemId": this.list[i].itemId,
							"num": this.list[i].num,
							"price": this.list[i].price
						})
						total = this.$public.jia(total,this.$public.cheng(this.list[i].num,this.list[i].price))
					}
				}
				if(arr.length===0){
					this.$message({
						message: '请选择要退的产品',
						type: 'error'
					})
					return 
				}
				let couponNo = ''
				if(this.radio===true){
					if(!this.$public.isNull(this.orderDetail.couponInfo)){
						couponNo = this.orderDetail.couponInfo.couponNo
					}
				}
				this.settlementParams = {
					"orderNo":this.orderDetail.orderNo,
					"remark": this.remark,
					"payRemark": "",
					// "isInto": true,
					"payList": [],
					"refundList": arr,
					"totalPrice": total,
					"couponNo": couponNo,
				}
				this.dialogSettlement_state = true
			},
			// 结算
			submit_dialogSettlement(obj){
				this.dialogSettlement_state = false
				if(obj.type==='success'){
					this.$emit("submit",{type:"success",data:""})
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
