<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">修改销售人员</div>
				<img class="HSDialogCloseImg" src="../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="100px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="销售人员">
							<el-select
							v-model="staffIdsArr"
							multiple
							filterable
							placeholder="请选择"
							>
								<el-option popper-class="select" v-for="item in staffList" :key="item.id" :label="item.basicInfo.realName"
								:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				// 预约信息
				staffIdsArr:[],
				staffList:[],
				
			}
		},
		computed:{
			
		},
		props:{
			salesList:{
				default:()=>{
					return []
				}
			}
		},
		mounted(){
			this.$nextTick(()=>{
				for(let i=0;i<this.salesList.length;i++){
					this.staffIdsArr.push(this.salesList[i].id)
				}
			})
			this.getStaffList();
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				if(this.staffIdsArr.length===0){
					this.$message({
						message: '请选择销售人员',
						type: 'error'
					})
					return 
				}
				let arr = []
				for(let i=0;i<this.staffIdsArr.length;i++){
					arr.push({
						id:this.staffIdsArr[i],
						name:this.$public.getMatchingItem(this.staffList,'id',this.staffIdsArr[i]).basicInfo.nickName
					})
				}
				this.$emit("submit",{type:'success',data:arr})
			},
			// 获取销售人员列表
			getStaffList(){
				let params = {
					pageIndex:1,
					pageSize:1000,
				}
				this.$http.post("/cms/StaffInfo/GetStaffInfoList",params).then(res=>{
					if(res.code===0){
						this.staffList = res.data.list
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
